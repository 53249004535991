@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-testimonials {
  .row {
    padding-bottom: 30px;
  }
}

.ds-testimonial {
  padding: 0 60px 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
  position: relative;
  font-family: Sans-Serif;

  &:before {
    content: "\201C";
    font-size: 100px;
    position: absolute;
    z-index: 12;
    top: 40px;
    left: 10px;
  }
  &:after {
    content: "\201D";
    font-size: 100px;
    position: absolute;
    z-index: 12;
    bottom: 40px;
    right: 10px;
  }

  .field-name-field-body p {
    font-size: 16px;
    @include breakpoint($desktop) {
      font-size: 28px;
      line-height: 1.2em;
    }
    text-align: center;
  }

  .field-name-field-title {
    //font-style: italic;
    padding-top: 30px;
    text-align: center;
    color: white;
    font-weight: 700;
  }
}
